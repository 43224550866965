<template>
  <div class="collapse-selector flex flex-col bg-pure-white shadow" :class="{ 'is-disabled': isDisabled }">
    <div v-if="isCollapsable" :tabindex="isDisabled ? -1 : 0"
      class="selector-label flex items-center flex-row px-40 h-90 focus:outline-none" :class="{
        'cursor-pointer': !isDisabled,
        'cursor-not-allowed': isDisabled,
      }" @click.prevent="onCollapsePress" @keydown.space.stop.prevent="onCollapsePress">
      <!--  -->
      <p :title="selectorLabel" class="label flex-1 text-lg leading-19 uppercase pr-40 truncate select-none" :class="{
        'text-slate-grey': modelIsCollapsed,
        'text-faded-red': !modelIsCollapsed,
      }">
        {{ selectorLabel }}
        <span v-if="placeholderLabel === 'constructions_search.selectors.building_class_text'" class="absolute ml-10 z-10"
          id="external-link">
          <IconInfo :size="25" class="" />
        </span>
      </p>
      <div class="flex items-center" :class="{
        'text-charcoal-grey': modelIsCollapsed,
        'text-faded-red': !modelIsCollapsed,
      }">
        <span v-show="currentValueIndex === -1" :title="$t('constructions_search.selectors.helper_text')"
          class="font-sans-bold text-sm leading-29 mr-22 uppercase select-none">
          {{ $t('constructions_search.selectors.helper_text') }}
        </span>
        <div class="icon-arrow inline-flex" :class="{ 'is-collapsed': modelIsCollapsed }">
          <IconArrow direction="down" />
        </div>
      </div>
    </div>
    <TransitionCollapse>
      <div v-show="!modelIsCollapsed" class="shadow-border">
        <div class="items px-40 py-30" :class="itemClassNames">
          <div v-for="(item, index) in languageDependentItems" :key="index" :tabindex="0" :title="item.label" class="option-item flex flex-col relative text-style-7
              cursor-pointer select-none hover:text-faded-red focus:outline-none" :class="{
                'text-faded-red': index === currentValueIndex,
                'items-start justify-start': !isImageType,
                'items-center': isImageType,
                'text-slate-grey': isImageType && index !== currentValueIndex,
              }" @click.prevent="onItemPress(index)" @keydown.space.stop.prevent="onItemPress(index)">
            
            <img v-if="item.imageSrc" :src="item.imageSrc" :alt="`Assembly type ${index + 1}`"
              class="block mb-13 h-120" />
            <p :class="{ 'is-image-type text-center': isImageType }">
              {{ item.label }} 
            </p>
            <IconCheckbox v-if="index === currentValueIndex && !isImageType" class="icon text-faded-red absolute h-20" />
          </div>
        </div>
      </div>
    </TransitionCollapse>
  </div>
</template>


<script>
import IconArrow from '@/components/icon/IconArrow';
import IconCheckbox from '@/components/icon/IconCheckbox';
import TransitionCollapse from '@/components/transition/TransitionCollapse';
import IconInfo from '@/components/icon/IconInfo';


export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
    },
    placeholderLabel: {
      type: String,
      default: '-',
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    disabledValues: {
      type: Array,
      default() {
        return [];
      },
    },
    isCollapsable: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemClassNames: {
      type: [Array, String],
      default: '',
    },
  },
  components: {
    IconInfo,
    IconArrow,
    IconCheckbox,
    TransitionCollapse,
  },
  data: () => ({
    currentValueIndex: -1,
    modelIsCollapsed: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue === '') this.currentValueIndex = -1;
        this.currentValueIndex = this.items.findIndex(item => item.value === newValue);
      },
    },
    isCollapsed: {
      immediate: true,
      handler(newValue) {
        this.modelIsCollapsed = newValue;
      },
    },
  },
  computed: {
    languageDependentItems() {
      if(this.$i18n.locale === 'fr'){
        return this.items.slice(0, -1)
      } else {return this.items}
    },
    isImageType() {
      return this.type === 'image';
    },
    selectorLabel() {
      if (this.currentValueIndex === -1) return this.$t(this.placeholderLabel);

      return this.items[this.currentValueIndex].label;
    },
  },
  methods: {
    onCollapsePress(event) {

      if (this.isDisabled) return;

      let path = event.composedPath ? event.composedPath() : event.path;
      if (typeof path !== 'undefined' && path.some(elem => elem.id === 'external-link')) {
        window.open(this.$t('constructions_search.selectors.download_link'), '_blank');
        return;
      }

      this.modelIsCollapsed = !this.modelIsCollapsed;
      this.$emit('update:isCollapsed', this.modelIsCollapsed);
    },
    onItemPress(optionIndex) {
      if (this.isDisabled) return;

      if (this.disabledValues.includes(this.items[optionIndex].value)) {
        this.$emit('item-disabled-pressed');
        return;
      }

      this.currentValueIndex = optionIndex;
      this.modelIsCollapsed = true;
      this.$emit('update:isCollapsed', true);

      this.$emit('change', this.items[optionIndex].value);
      this.$emit('input', this.items[optionIndex].value);
    },
  },
};
</script>


<style lang="scss" scoped>
.collapse-selector {
  &.is-disabled {
    @apply opacity-50;
  }

  .selector-label {
    .icon-arrow {
      transform: rotate(180deg);
      transition: transform 0.3s linear; // TODO: Move to transitions file
    }

    .icon-arrow.is-collapsed {
      transform: rotate(0deg);
      transition: transform 0.3s linear;
    }
  }

  .selector-label:focus .label {
    @apply text-faded-red;
  }

  .items {
    display: grid;
    grid-row-gap: 22px;
    grid-column-gap: 30px;

    .option-item {
      &:focus {
        @apply text-faded-red;
      }

      .is-image-type {
        @apply font-sans-bold text-base leading-29 uppercase;
      }

      .icon {
        left: -28px;
        top: 0;
      }
    }
  }
}
</style>
