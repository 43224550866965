import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import defaultLocalesMessages from './locales/de';


Vue.use(VueI18n);


export const defaultLocale = 'de';

export const availableLocales = ['de', 'en', 'fr'];

const loadedLocales = [defaultLocale]; // our default language that is preloaded
const defaultMessagesConfig = {};
defaultMessagesConfig[defaultLocale] = {
  ...defaultLocalesMessages,
};

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: defaultMessagesConfig,
  silentTranslationWarn: true, // removes -> [vue-i18n] Value of key '<<value>>' is not a string!
});


function setI18nLocale(locale) {
  i18n.locale = locale;
  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html').setAttribute('lang', locale);

  return locale;
}

export function loadLocaleAsync(locale) {
  if (i18n.locale !== locale) {
    if (!loadedLocales.includes(locale)) {
      return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/locales/${locale}`).then((localeModule) => {
        i18n.setLocaleMessage(locale, localeModule.default);
        loadedLocales.push(locale);

        return setI18nLocale(locale);
      });
    }

    return Promise.resolve(setI18nLocale(locale));
  }

  return Promise.resolve(locale);
}
