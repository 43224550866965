export default {
  firebase_error_codes: {
    'auth/email-already-in-use': 'Diese E-Mail-Adresse wird bereits von einem anderen Konto benutzt.',
    'auth/operation-not-allowed': 'Es ist nicht möglich, sich bei diesem Projekt mit einem Passwort einzuloggen.',
    'auth/too-many-requests': 'Wegen ungewöhnlich hoher Aktivität haben wir alle Anforderungen von diesem Gerät blockiert. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
    'auth/user-not-found': 'Dieser Benutzer ist uns nicht bekannt.',
    'auth/wrong-password': 'Dieses Passwort ist ungültig.',
    'auth/user-disabled': 'Dieses Benutzerkonto wurde von einem Administrator gesperrt.',
    'auth/expired-action-code': 'Der Kennwortrücksetzungscode ist abgelaufen.',
    'auth/invalid-action-code': 'Der Kennwortrücksetzcode ist ungültig. Code ist fehlerhaft oder wurde bereits verwendet.',
    'auth/user-token-expired': 'Der Token dieses Benutzers ist nicht mehr gültig. Der Benutzer muss sich wieder einloggen.',
    'auth/invalid-user-token': 'Der Refresh-Token ist ungültig.',
    'auth/invalid-credential': 'Der angegebene Grant-Type ist ungültig.',
  },
  navigation: {
    top: {
      bookmarks_text: 'Merklisten',
      account_settings_text: 'Account-Einstellungen',
      sign_in_text: 'Login',
      sign_out_text: 'Abmelden',
      language_selector: {
        de: 'Deutsch',
        en: 'English',
        fr: 'français',
      },
    },
    bottom: {
      home_text: 'Home',
      assembly_text: 'Bauteile',
      tools_text: 'Tools',
      bookmark_text: 'Merkliste',
      next_button: {
        main_text: 'Weiter',
        result_count_text: 'Bauteile',
        result_count_empty_text: 'Bauteile gefunden',
      },
    },
    view_titles: {
      home_text: '',
      assembly_search: {
        manual_text: 'Manuelle Bauteil-Filterung',
        preset_text: 'Geführte Bauteilsuche',
      },
      reset_password_text: 'Passwort ändern',
      impressum_text: 'Impressum',
      data_protection_text: 'Datenschutz',
      tools_text: '',
      product_detail_text: 'Produktdetail',
      bookmarks_text: 'Merklisten',
      data_privacy_text: 'Datenschutz',
      search_results: 'Search results',
    },
  },
  modal: {
    authentication: {
      form: {
        firstName: {
          placeholder_text: 'Vorname',
          is_empty_text: 'Bitte geben sie Ihren Vornamen ein  ',
        },
        lastName: {
          placeholder_text: 'Nachname',
          is_empty_text: 'Bitte geben sie Ihren Nachnamen ein  ',
        },
        company_name: {
          placeholder_text: 'Unternehmen',
          is_empty_text: 'Bitte geben Sie Ihren Unternehmensnamen ein  ',
        },
        street: {
          placeholder_text: 'Strasse und Nr',
          is_empty_text: 'Bitte geben Sie ihre Strasse und Nr. ein  ',
        },
        zip: {
          placeholder_text: 'PLZ',
          is_empty_text: 'Bitte geben Sie ihre Postleitzahl ein ',
        },
        city: {
          placeholder_text: 'Ort',
          is_empty_text: 'Bitte geben Sie einen Ort an  ',
        },
        country: {
          placeholder_text: 'Land',
          is_empty_text: 'Bitte wählen Sie Ihr Land aus  ',
        },
        phone_number: {
          placeholder_text: 'Telefonnummer',
          is_empty_text: 'Bitte geben Sie Ihre Telefonnummer ein  ',
        },
        email: {
          placeholder_text: 'E-Mail Adresse',
          is_empty_text: 'Bitte geben Sie eine gültige Email-Adresse ein',
        },
        dataProtect: {
          is_empty_text: 'Bitte akzeptieren Sie unsere Datenschutzbestimmungen',
        },
        password: {
          placeholder_text: 'Passwort',
          description_text: '8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Ziffern und Symbolen verwenden',
          is_invalid_text: 'Bitte geben Sie ein gültiges Passwort ein',
        },
        error_text: 'Nicht alle Felder wurden korrekt ausgefüllt. Bitte überprüfen Sie Ihre Angaben.',
      },
      login: {
        title_text: 'Anmeldung',
        description_text: 'Melden Sie sich an, um Ihre Merklisten oder Daten zu bearbeiten.',
        button_text: 'Anmelden',
        no_account_yet_text: 'Noch keinen Account?',
        register_here_text: 'Hier Registrieren',
        forgot_password_text: 'Passwort vergessen?',
        success: {
          title_text: 'Anmeldung Erfolgreich',
          main_text: 'Herzlich willkommen {userName}',
          description_text: 'Sie können jetzt Merklisten und Bauteile speichern.',
        },
      },
      register: {
        title_text: 'Registrierung',
        description_text: 'Registrieren Sie sich, um Bauteile in individuellen Merklisten zu speichern.',
        button_text: 'Registrieren',
        data_protection: {
          main_text: 'Ich habe die {dataProtectionLink} gelesen und akzeptiert.',
          label_text: 'Datenschutzhinweise',
          label_link: 'https://www.swisskrono.com/ch-de/datenschutz/#',
        },
        success: {
          title_text: 'Anmeldung Erfolgreich',
          main_text: 'Herzlich willkommen {userName}',
          description_text: 'Sie können jetzt Projekt-Listen und Bauteile speichern.',
        },
      },
      getUpdates: {
        data_protection: {
          main_text: 'Einwilligungoption per Mail oder Post über News informiert werden zu können',
          label_text: 'Datenschutzhinweise',
          label_link: 'https://www.swisskrono.com/ch-de/datenschutz/#',
        },
      },
      login_register_success: {
        first_part_text: 'Wir empfehlen Ihnen, sich den',
        second_part_text: 'Timberplanner als Bookmark / Lesezeichen',
        third_part_text: 'für einen schnelleren Zugriff zu speichern.',
      },
      reset_password_send_email: {
        title_text: 'Passwort Zurücksetzen',
        description_text: 'Bitte geben Sie die E-Mail Adresse ein, die mit Ihrem Account verbunden ist. Sie bekommen dann einen Link, mit dem Sie ihr Passwort ändern können.',
        back_to_login_text: 'Zurück zum Login',
        button_text: 'Senden',
        success: {
          title_text: 'Passwort Zurücksetzen',
          main_text: 'Link erfolgreich verschickt',
          description_text: 'Bitte rufen sie die E-Mail auf, die wir Ihnen geschickt haben.',
        },
      },
      reset_password_set_password: {
        description_text: 'Bitte geben sie hier ein neues Passwort ein. Ihr altes wird damit überschreiben.',
        button_text: 'Aktualisieren',
      },
    },
  },
  home: {
    welcome_text: 'Willkommen bei Ihrem',
    main_text: 'SWISS KRONO\nBauteil-planer',
    predefined_search: {
      main_text: 'Bauteilfilterung nach gesetzlichen Anforderungen',
      region_selector_placeholder_text: 'Bundesland wählen',
    },
    manual_search: {
      main_text: 'Manuelle Bauteil-Filterung',
      link_text: 'Zur Filterung',
    },
    registration: {
      main_text: 'Account und Merklisten anlegen',
      link_text: 'Registrieren',
    },
  },
  bookmarks: {
    title_text: 'Hallo, {userName}',
    lists_title_text: 'Merklisten',
    general_list_text: 'Allgemeine Liste',
    new_list_text: 'Neue Liste',
    rename_list_text: 'Liste umbenennen',
    delete_list_text: 'Liste löschen',
    empty_list: {
      main_text: 'In dieser Liste sind noch keine Bauteile gespeichert.',
      description_text: 'Bauteile hinzufügen',
      predefined_search_text: 'Nach gesetzlichen Anforderungen',
      manual_search_text: 'Manuelle Bauteil-Filterung',
    },
    modal: {
      form: {
        name: {
          placeholder_text: 'Name',
          is_empty_text: 'Bitte geben sie einen Namen ein',
        },
      },
      new_bookmark_list: {
        title_text: 'Neue Liste Anlegen',
        description_text: 'Bitte geben sie den Namen ihrer neuen Merkliste ein',
        button_text: 'Speichern',
        back_to_save_bookmarked_product_text: 'Zurück',
      },
      rename_bookmark_list: {
        title_text: 'Liste Umbenennen',
        description_text: 'Geben Sie einen neuen Namen ein',
        button_text: 'Speichern',
      },
      delete_bookmark_list: {
        title_text: 'Liste löschen',
        description_text: 'Möchten Sie “{bookmarkListName}” wirklich löschen? Alle in dieser Liste gespeicherten Bauteile werden dadurch entfernt.',
        button_text: 'Löschen',
      },
      save_bookmarked_product: {
        title_text: 'Bauteil Merken',
        description_text: 'Wählen Sie die Liste in der das Bauteil gespeichert werden soll.',
        button_text: 'Speichern',
      },
    },
  },
  constructions_search: {
    steps: {
      first_step: {
        main_text: 'Basisdaten',
        helper_text: 'Definieren Sie die Details des Bauvorhabens',
      },
      second_step: {
        main_text: 'Bauteil',
        helper_text: 'Wählen Sie eine Bauteilkategorie, um Anforderungen zu ermitteln',
        min_requirements_text: 'Gesetzliche Mindestanforderungen',
        airborne_sound_insulation_text: 'Luftschall',
        footfall_sound_text: 'Trittschall',
        fire_resistance_text: 'Brandschutz',
        fire_resistance_class_text: 'Klasse',
      },
      third_step: {
        main_text: 'Anforderungen',
        helper_text: 'Brand- und Schallschutzwerte werden automatisch ermittelt',
        filter_form: {
          description_text: 'Mindestanforderungen und darüber liegend. Bitte passen Sie die Werte Ihren Bedürfnissen an.',
          without_rw_lnw_text: 'Ohne',
          rw: {
            main_text: 'Schalldämmmass',
            info_description_text: 'Die Schallnebenwege sind zusätzlich zu berücksichtigen. Bitte passen Sie ggf. den Wert an. Für Bereiche mit erhöhtem Außenlärm (Straßen-, Schienenverkehr, Fluglärm) sind weitere Anforderungen zu beachten.',
            adaptation_text: 'Spektralen Anpassungswert berücksichtigen',
          },
          lnw: {
            main_text: 'Norm-Trittschallpegel',
            info_description_text: 'Die Schallnebenwege sind zusätzlich zu berücksichtigen. Bitte passen Sie ggf. den Wert an. Für Bereiche mit erhöhtem Außenlärm (Straßen-, Schienenverkehr, Fluglärm) sind weitere Anforderungen zu beachten.',
            adaptation_text: 'Spektralen Anpassungswert berücksichtigen',
          },
          fire_resistance_text: 'Feuerwiderstandsklassen',
          additional_specs_text: 'Zusatzanforderungen',
          mechanical_influence_text: 'M (Mechanische Einwirkung)',
          cladding_criterion_text: {
            main_text: 'K{subText} 60 (Kapselkriterium)',
            sub_text: '2',
          },
          solid_wood_text: 'SWISS KRONO MAGNUMBOARD OSB',
          tooltips: {
            airborn_insulation_text: 'Die Schallnebenwege sind zusätzlich zu berücksichtigen. Bitte passen Sie ggf. den Wert an. Für Bereiche mit erhöhtem Außenlärm (Straßen-, Schienenverkehr, Fluglärm) sind weitere Anforderungen zu beachten.',
            airborn_insulation_adaptation_text: 'Spektrum-Anpassungswerte C100-3150, C 50-3150 sind Korrekturfaktoren zur Bewertung von Frequenzeinbrüchen an Schallpegelkurven für Innenlärm.',
            footfall_insulation_text: 'Die Schallnebenwege sind zusätzlich zu berücksichtigen. Bitte passen Sie ggf. den Wert an. Für Bereiche mit erhöhtem Außenlärm (Straßen-, Schienenverkehr, Fluglärm) sind weitere Anforderungen zu beachten.',
            footfall_insulation_adaptation_text: 'Korrekturfaktor zur Bewertung vorrangig tieffrequenter Trittschallanteile. Der Spektrum- Anpassungswert Cl50-2500 berücksichtigt einen erweiterten Frequenzbereich beim Trittschall von 50 bis 2500 Hz und beim Luftschall von 100 bis 3150 Hz.',
            fire_resistance_text: 'Die Feuerwiderstandsklassen F... entsprechen den Anforderungen nach DIN 4102-4:2016-05. Die Brandklassen nach EN entsprechen der Lignum Dokumentation "4.1 Bauteile in Holz – Decken, Wände und Bekleidungen mit Feuerwiderstand".',
            additional_specs_text: 'Mit MB sind nur Bauteile mit SWISS KRONO MAGNUMBOARD OSB zulässig',
          },
        },
      },
    },
    selectors: {
      helper_text: 'Bitte wählen',
      region_text: 'Bundesland',
      building_type_text: 'Gebäudeart',
      building_class_text: 'Gebäudeklasse',
      assembly_type_text: 'Bauteil',
      assembly_type_specific_text: 'Spezifische bauteile',
      download_link: 'https://www.swisskrono.de/assets/mime/-UTQ3ZSaYJHjwd0s8XTQ4Iuvr5tPuxfcFdFZT8HhUrKWLBpCz/P01395_Definition-GK_0810_c.pdf'
    },
    modal: {
      confirm_redirection_type: {
        title_text: 'Warnung',
        description_text: 'Wenn Sie fortfahren, verlieren Sie alle Ihre Filtereinstellungen. Sind Sie sicher, dass Sie fortfahren möchten?',
        first_button_text: 'Fortfahren',
        second_button_text: 'Zurück',
      },
      disabled_assembly_type: {
        title_text: 'Keine auswahl möglich',
        description_text: 'Für das angewählte Bauteil ist keine Auswahl möglich. Entweder ist dieses in der gewählten Gebäudeklasse nicht verfügbar oder in Holzbauweise nicht zulässig. Bitte wählen Sie ein anderes Bauteil.',
        button_text: 'Ok',
      },
      disabled_assembly_type_specific: {
        title_text: 'Keine auswahl möglich',
        description_text: 'Für das angewählte Bauteil ist keine Auswahl möglich. Entweder ist dieses in der gewählten Gebäudeklasse nicht verfügbar oder in Holzbauweise nicht zulässig. Bitte wählen Sie ein anderes spezifisches Bauteil.',
        button_text: 'Ok',
      },
    },
  },
  tools: {
    top_left_title_text: 'BERECHNUNGSTOOLS',
    top_right_title_text: 'stellt Ihnen verschiedene Berechnungstools kostenlos zur Verfügung.',
    first_tool_title_text: 'U-WERT-RECHNER',
    first_tool_subtitle_text: '',
    first_tool_main_text: 'Berechnen Sie den Gesamt-Wärmedurchgangswiderstand R und -koeffizient U sowie die Werte für Querschnitt und Rahmen.',
    first_tool_sub_text: 'Die Berechung des U-Wertes erfolgt gemäß DIN 4108.',
    second_tool_title_text: 'TAUWASSER-RECHNER',
    second_tool_subtitle_text: '',
    second_tool_main_text: 'Berechnen Sie einfach durch Auswahl der verwendeten Materialien, ob die Konstruktion Tauwasserfrei ist.',
    second_tool_sub_text: 'Berechnung des Tauwasserausfalls nach Glaser gemäß DIN 4108-3.',
    third_tool_title_text: 'STATIK + BRANDSCHUTZ',
    third_tool_subtitle_text: '',
    third_tool_main_text: 'Berechnen Sie anhand der Höhe und Dicke einer MAGNUMBOARD-Wand die maximale Belastbarkeit, über die Abbrandrate inklusive Feuerwiderstand.',
    third_tool_sub_text: 'Berechnung der Statik nach DIN EN 1995-1-1 und DIN EN 1995-1-2 (Brandfall).',
  },
  impressum: {
    top_left_title_text: 'IMPRESSUM',
    top_right_title_text: 'Verantwortlich für diesen Webauftritt ist:',
    copyright_open_link_text: 'Copyright öffnen',
  },
  data_privacy: {
    top_left_title_text: 'Datenschutzerklärung (Timberplanner.com)',
  },
  dialog: {
    buttons: {
      save_text: 'SPEICHERN',
      save_changes_text: 'ÄNDERUNGEN SPEICHERN',
      change_password_text: 'PASSWORT ÄNDERN',
      update_text: 'AKTUALISIEREN',
      delete_account_text: 'ACCOUNT LÖSCHEN',
      continue_text: 'WEITER',
      log_in_text: 'ANMELDEN',
      register_text: 'REGISTRIEREN',
      cancel_text: 'ABBRECHEN',
    },
  },
  product_details: {
    data_labels: {
      product_text: 'PRODUKT',
      manufacturer_text: 'HERSTELLER',
      thickness_text: 'DICKE',
      weight_text: 'GEWICHT',
      width_text: 'BREITE (B)',
      mast_distance_text: 'ACHSABSTAND (E)',
    },
    back_to_results_text: 'Zurück zur Ergebnisliste',
    save_bookmark_text: 'Merken',
    saved_bookmark_text: 'Gemerkt',
    download_ifc_text: 'Download IFC Datei',
    specifications_text: 'Ausschreib.- text GAEB XML',
    specifications_extra: 'Ausschreib.- text Excel',
    layer_structure_label_text: 'SCHICHTAUFBAU',
    first_tab_label_text: 'BASIS-BAUTEIL',
    second_tab_label_text: 'SCHALLSCHUTZ',
    third_tab_label_text: 'BRANDSCHUTZ',
    installation_height_label_text: 'Aufbauhöhe',
    mass_label_text: 'Gewicht',
    source_label_text: 'Quelle',
    airborne_sound_label_text: 'Luftschall',
    footfall_label_text: 'Trittschall',
    verified_calculation_label_text: 'Verifizierte Berechnung',
    learn_acustic_label_text: 'Mehr zur Bestimmung der Schallschutzwerte erfahren Sie hier:',
    certificate_label_text: 'Prüfzeugnis',
    fire_protection_class_label_text: 'Brandschutzklasse',
    standard_source_label_text: 'Norm / Quelle',
    additional_requirements_label_text: 'Zusätzliche / erweiterte Anforderungen',
    first_tool_title_text: 'U-WERT-RECHNER',
    second_tool_title_text: 'TAUWASSER-RECHNER',
    third_tool_title_text: 'STATIK + BRANDSCHUTZ',
    more_informations_title_text: 'WEITERE INFORMATIONEN',
    learn_more_text: 'Erfahren Sie mehr über unsere Produkte.',
    information_on_use_more_text: 'VERARBEITUNGSHINWEISE',
    find_important_informations_text: 'Hier finden Sie alle wichtigen Informationen zum Verarbeiten von Swiss Krono Produkten.',
    export_building_text: 'Exportieren Sie das Bauteil für weitere Nachweise',
    adaptation_value_text: 'Spektraler Anpassungswert:',
  },
  search_results: {
    more_results_loading_text: 'Weitere Bauteile werden geladen',
    results_found_text: 'BAUTEILE GEFUNDEN',
    filter_text: 'FILTER',
  },
  view_footer: {
    main_title_text: 'Swiss Krono\nBauteil-Planer',
    contact_text: 'Kontakt',
    contact_link: 'https://www.swisskrono.com/de-de/kontakt/#/',
    career_text: 'Karriere',
    career_link: 'https://www.swisskrono.com/ch-de/ueber-uns/karriere/#',
    press_text: 'News & Veranstaltungen',
    press_link: 'https://www.swisskrono.com/de-de/ueber-uns/news-veranstaltungen/#/',
    data_protection_text: 'Datenschutz',
    impressum_text: 'Impressum',
    need_help_text: 'Benötigen Sie Hilfe?',
    glossary_text: 'Glossar',
    glossary_link: 'https://www.swisskrono.com/ch-de/glossar/#',
    downloads_link: 'https://www.swisskrono.com/de-de/downloadbereich-holzwerkstoffe/#/',
  },
  cookies: {
    cookies_text: 'Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen hierzu erhalten Sie in unserer ',
    cookies_data_protection_text: 'Erklärung zum Datenschutz',
  },
  countries: {
    Afghanistan: "Afghanistan",
    Albania: "Albanien",
    Algeria: "Algerien",
    American_Samoa: "Amerikanisch-Samoa",
    Andorra: "Andorra",Angola: "Angola",
    Anguilla: "Anguilla",
    Antarctica: "Antarktis",
    Antigua_and_Barbuda: "Antigua und Barbuda",
    Argentina: "Argentinien",
    Armenia: "Armenien",
    Aruba: "Aruba",
    Azerbaijan: "Aserbaidschan",
    Australia: "Australien",
    Egypt: "Ägypten",
    Equatorial_Guinea: "Äquatorialguinea",
    Ethiopia: "Äthiopien",
    Bahrain: "Bahrain",
    Bangladesh: "Bangladesch",
    Barbados: "Barbados",
    Belgium: "Belgien",
    Belize: "Belize",
    Benin: "Benin",
    Bermuda: "Bermuda",
    Bhutan: "Bhutan",
    Bolivia: "Bolivien",
    Bonaire_Sint_Eustatius_and_Saba: "Bonaire, Sint Eustatius und Saba",
    Bosnia_and_Herzegovina: "Bosnien und Herzegowina",
    Botswana: "Botsuana",
    Bouvet_Island: "Bouvetinsel",
    Brazil: "Brasilien",
    British_Virgin_Islands: "Britische Jungferninseln",
    British_Indian_Ocean_Territory: "Britisches Territorium im Indischen Ozean",
    Brunei: "Brunei Darussalam",
    Bulgaria: "Bulgarien",
    Burkina_Faso: "Burkina Faso",
    Burundi: "Burundi",
    Cayman_Islands: "Cayman Inseln",
    Chile: "Chile",
    China: "China",
    Cook_Islands: "Cookinseln",
    Costa_Rica: "Costa Rica",
    Curaçao: "Curacao",
    Germany: "Deutschland",
    The_Bahamas: "Die Bahamas",
    Dominica: "Dominica",
    Dominican_Republic: "Dominikanische Republik",
    Djibouti: "Dschibuti",
    Denmark: "Dänemark",
    Ecuador: "Ecuador",
    El_Salvador: "El Salvador",
    Cote_dIvoire: "Elfenbeinküste",
    Eritrea: "Eritrea",
    Estonia: "Estland",
    Falkland_Islands: "Falklandinseln",
    Fiji: "Fidschi",
    Finland: "Finnland",
    France: "Frankreich",
    French_Polynesia: "Französisch Polynesien",
    French_Guiana: "Französisch-Guayana",
    Faroes: "Färöer",
    Gabon: "Gabun",
    Gambia: "Gambia",
    Georgia: "Georgien",
    Ghana: "Ghana",
    Gibraltar: "Gibraltar",
    Grenada: "Grenada",
    Greece: "Griechenland",
    United_Kingdom: "Großbritannien",
    Greenland: "Grönland",
    Guadeloupe: "Guadeloupe",
    Guam: "Guam",
    Guatemala: "Guatemala",
    Guernsey: "Guernsey",
    Guinea: "Guinea",
    Guinea_Bissau: "Guinea-Bissau",
    Guyana: "Guyana",
    Haiti: "Haiti",
    Heard_Island_and_McDonald_Islands: "Heard und McDonaldinseln",
    Honduras: "Honduras",
    Hong_Kong_SAR_of_China: "Hong Kong S.A.R., China",
    India: "Indien",
    Indonesia: "Indonesien",
    Isle_of_Man: "Insel Man",
    Iraq: "Irak",
    Iran: "Iran",
    Ireland: "Irland",
    Iceland: "Island",
    Israel: "Israel",
    Italy: "Italien",
    Jamaica: "Jamaika",
    Japan: "Japan",
    Yemen: "Jemen",
    Jersey: "Jersey",
    Jordan: "Jordanien",
    Cambodia: "Kambodscha",
    Cameroon: "Kamerun",
    Canada: "Kanada",
    Cape_Verde: "Kap Verde",
    Kazakhstan: "Kasachstan",
    Qatar: "Katar",
    Kenya: "Kenia",
    Kyrgyzstan: "Kirgisistan",
    Kiribati: "Kiribati",
    United_States_Minor_Outlying_Islands: "Kleinere abgelegene Inseln der Vereinigten Staaten",
    Cocos_Islands: "Kokosinseln (Keelinginseln)",
    Colombia: "Kolumbien",
    Comoros: "Komoren",
    Congo: "Kongo",
    Congo_Brazzaville: "Kongo-Brazzaville",
    Croatia: "Kroatien",
    Cuba: "Kuba",
    Kuwait: "Kuwait",
    Laos: "Laos",
    Lesotho: "Lesotho",
    Latvia: "Lettland",
    Lebanon: "Libanon",
    Liberia: "Liberia",
    Libya: "Libyen",
    Liechtenstein: "Liechtenstein",
    Lithuania: "Litauen",
    Luxembourg: "Luxemburg",
    Macao_SAR_of_China: "Macau S.A.R., China",
    Madagascar: "Madagaskar",
    Malawi: "Malawi",
    Malaysia: "Malaysia",
    Maldives: "Malediven",
    Mali: "Mali",
    Malta: "Malta",
    Morocco: "Marokko",
    Marshall_Islands: "Marschallinseln",
    Martinique: "Martinique",
    Mauritania: "Mauretanien",
    Mauritius: "Mauritius",
    Mayotte: "Mayotte",
    Macedonia: "Mazedonien",
    Mexico: "Mexiko",
    Micronesia: "Mikronesien",
    Moldova: "Moldawien",
    Monaco: "Monaco",
    Mongolia: "Mongolei",
    Montenegro: "Montenegro",
    Montserrat: "Montserrat",
    Mozambique: "Mosambik",
    Myanmar: "Myanmar",
    Namibia: "Namibia",
    Nauru: "Nauru",
    Nepal: "Nepal",
    New_Caledonia: "Neukaledonien",
    New_Zealand: "Neuseeland",
    Nicaragua: "Nicaragua",
    Netherlands: "Niederlande",
    Niger: "Niger",
    Nigeria: "Nigeria",
    Niue: "Niue",
    North_Korea: "Nord Korea",
    Norfolk_Island: "Norfolkinsel",
    Norway: "Norwegen",
    Northern_Marianas: "Nördliche Marianen",
    Oman: "Oman",
    Austria: "Österreich",
    Pakistan: "Pakistan",
    Palau: "Palau",
    Palestine: "Palästina",
    Panama: "Panama",
    Papua_New_Guinea: "Papua-Neuguinea",
    Paraguay: "Paraguay",
    Peru: "Peru",
    Philippines: "Philippinen",
    Pitcairn_Islands: "Pitcairn",
    Poland: "Polen",
    Portugal: "Portugal",
    Puerto_Rico: "Puerto Rico",
    Reunion: "Reunion",
    Rwanda: "Ruanda",
    Romania: "Rumänien",
    Russia: "Russische Föderation",
    Saint_Pierre_and_Miquelon: "Saint Pierre und Miquelon",
    Saint_Barthélemy: "Saint-Barthélemy",
    Saint_Martin: "Saint-Martin",
    Solomon_Islands: "Salomon-Inseln",
    Saint_Lucia: "Salomonen",
    Zambia: "Sambia",
    Samoa: "Samoa",
    San_Marino: "San Marino",
    Saudi_Arabia: "Saudi-Arabien",
    Sweden: "Schweden",
    Switzerland: "Schweiz",
    Senegal: "Senegal",
    Serbia: "Serbien",
    Seychelles: "Seychellen",
    Sierra_Leone: "Sierra Leone",
    Zimbabwe: "Simbabwe",
    Singapore: "Singapur",
    Sint_Maarten: "Sint Maarten",
    Slovakia: "Slowakei",
    Slovenia: "Slowenien",
    Somalia: "Somalia",
    South_Africa: "Spanien",
    Spain: "Spanien",
    Sri_Lanka: "Sri Lanka",
    Saint_Helena_Ascension_and_Tristan_da_Cunha: "St. Helena, Himmelfahrt und Tristan da Cunha",
    Saint_Kitts_and_Nevis: "St. Kitts und Nevis",
    Saint_Vincent_and_the_Grenadines: "St. Vincent und die Grenadinen",
    Sudan: "Sudan",
    Suriname: "Suriname",
    Svalbard: "Svalbard und Jan Mayen",
    Swaziland: "Swasiland",
    Syria: "Syrien",
    Sao_Tome_e_Principe: "São Tomé e Príncipe",
    South_Georgia_and_the_South_Sandwich_Islands: "Süd-Georgien und die südlichen Sandwich-Inseln",
    French_Southern_Territories: "Südfranzösische Territorien",
    South_Korea: "Südkorea",
    South_Sudan: "Südsudan",
    Tajikistan: "Tadschikistan",
    Taiwan: "Taiwan",
    Tanzania: "Tansania",
    Thailand: "Thailand",
    Togo: "Togo",
    Timor_Leste: "Tokelau",
    Tokelau: "Tokelau",
    Tonga: "Tonga",
    Trinidad_and_Tobago: "Trinidad und Tobago",
    Chad: "Tschad",
    Czech_Republic: "Tschechien",
    Tunisia: "Tunesien",
    Turkmenistan: "Turkmenistan",
    Turks_and_Caicos_Islands: "Turks- und Caicosinseln",
    Tuvalu: "Tuvalu",
    Turkey: "Türkei",
    US_Virgin_Islands: "US Jungferninseln",
    Uganda: "Uganda",
    Ukraine: "Ukraine",
    Hungary: "Ungarn",
    Uruguay: "Uruguay",
    Uzbekistan: "Usbekistan",
    Vanuatu: "Vanuatu",
    Vatican_City: "Vatikanstadt",
    Venezuela: "Venezuela",
    United_Arab_Emirates: "Vereinigte Arabische Emirate",
    United_States: "Vereinigte Staaten",
    Vietnam: "Vietnam",
    Wallis_and_Futuna: "Wallis und Futuna",
    Christmas_Island: "Weihnachtsinsel",
    Belarus: "Weißrussland",
    Western_Sahara: "Westsahara",
    Central_African_Republic: "Zentralafrikanische Republik",
    Cyprus: "Zypern",
  },
};
