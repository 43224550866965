/* eslint-disable no-empty-pattern,no-continue */
import { FBFirestoreRef } from '@/helpers/firebaseServicesHelper';


const DBRegionsRef = FBFirestoreRef.collection('regions');
const DBBuildingTypesRef = FBFirestoreRef.collection('buildings');
const DBBuildingClassesRef = FBFirestoreRef.collection('classes');
const DBAssemblyTypesRef = FBFirestoreRef.collection('types');
const DBFiltersRef = FBFirestoreRef.collection('filters');

const resistanceValues = [{
  value: 'F 0',
  valueOrder: 1,
  groupIndex: 0,
}, {
  value: 'F 30',
  valueOrder: 5,
  groupIndex: 0,
}, {
  value: 'F 60',
  valueOrder: 10,
  groupIndex: 0,
}, {
  value: 'F 90',
  valueOrder: 16,
  groupIndex: 0,
}, {
  value: 'EI 30',
  valueOrder: 3,
  groupIndex: 1,
}, {
  value: 'REI 30',
  valueOrder: 4,
  groupIndex: 1,
}, {
  value: 'EI 60',
  valueOrder: 7,
  groupIndex: 1,
}, {
  value: 'R 60',
  valueOrder: 8,
  groupIndex: 1,
}, {
  value: 'REI 60',
  valueOrder: 9,
  groupIndex: 2,
}, {
  value: 'EI 60-M',
  valueOrder: 11,
  groupIndex: 2,
}, {
  value: 'EI 90',
  valueOrder: 13,
  groupIndex: 2,
}, {
  value: 'R 90',
  valueOrder: 14,
  groupIndex: 2,
}, {
  value: 'REI 90',
  valueOrder: 15,
  groupIndex: 2,
}];


function getDBSelectorValues(DBRef) {
  return DBRef.get()
    .then((querySnapshot) => {
      const DBValues = [];

      querySnapshot.forEach((doc) => {
        DBValues.push({
          value: doc.id,
          data: doc.data(),
        });
      });
      return Promise.resolve(DBValues);
    })
    .catch(error => Promise.reject(error));
}

function arrayContainsArray(array, arrayToContain) {
  return arrayToContain.every(arrayValue => array.includes(arrayValue));
}

const defaultActiveStepIndex = 0;
const firstLoadProductLimit = 30;


export default {
  namespaced: true,
  state: {
    isInitialLoading: true,
    activeStepIndex: defaultActiveStepIndex,
    searchType: '',
    DBValues: {
      regions: [],
      buildingTypes: [],
      buildingClasses: [],
      assemblyTypes: [],
      assemblyTypeSpecifics: [],
      fireResistances: resistanceValues,
      disabledAssemblyTypes: {},
    },
    selectedValues: {
      region: '',
      buildingType: '',
      buildingClass: '',
      assemblyType: '',
      assemblyTypeSpecific: '',
    },
    selectedSpecificationValues: {
      fireResistances: [],
      rwAdaptation: {
        range: [0, 0],
        offset: '',
      },
      lnwAdaptation: {
        range: [0, 0],
        offset: '',
      },
      customFilters: [],
    },
    allProductsIsLoading: true,
    allProducts: [],
    filteredProducts: [],
    filterIsActive: false,
  },
  getters: {
    filterIsActive: state => state.filterIsActive,
    searchTypeIsManual: state => state.searchType === 'manual',
    searchTypeIsPreset: state => state.searchType === 'preset',
    allowedStepIndexes: (state, getters) => {
      const allowedStepIndexes = [0];

      if (getters.searchTypeIsManual && state.activeStepIndex === 0) {
        if (state.selectedValues.assemblyType !== '') allowedStepIndexes.push(1);
      } else if (getters.searchTypeIsPreset) {
        if (state.selectedValues.region !== ''
          && state.selectedValues.buildingType !== ''
          && state.selectedValues.buildingClass !== '') {
          allowedStepIndexes.push(1);

          if (state.selectedValues.assemblyType
            && state.selectedValues.assemblyTypeSpecific !== ''
            && !getters.fireResistanceHasNoAvailableProducts) {
            allowedStepIndexes.push(2);
          }
        }
      }

      return allowedStepIndexes;
    },
    selectedAssemblyType: state => state.DBValues.assemblyTypes
      .find(type => type.value === state.selectedValues.assemblyType),
    selectedAssemblyTypeSpecific: state => state.DBValues.assemblyTypeSpecifics
      .find(option => option.value === state.selectedValues.assemblyTypeSpecific),
    fireResistanceHasNoAvailableProducts: (state, getters) => !!(getters.selectedFireResistance === 'x'),
    // TODO: Rename as it now contains customFilters aswell
    selectedFireResistance: (state, getters) => {
      if (getters.selectedAssemblyTypeSpecific
        && getters.selectedAssemblyTypeSpecific.data.fireResistanceClassesByBuildingType
        // eslint-disable-next-line max-len
        && getters.selectedAssemblyTypeSpecific.data.fireResistanceClassesByBuildingType[state.selectedValues.buildingClass]) {
        // eslint-disable-next-line max-len
        return getters.selectedAssemblyTypeSpecific.data.fireResistanceClassesByBuildingType[state.selectedValues.buildingClass];
      }

      return false;
    },
  },
  mutations: {
    RESET_SEARCH_STATE(state) {
      state.activeStepIndex = defaultActiveStepIndex;
      state.searchType = '';
      state.selectedValues = {
        region: '',
        buildingType: '',
        buildingClass: '',
        assemblyType: '',
        assemblyTypeSpecific: '',
      };
      state.filterIsActive = false;
      // state.DBValues.assemblyTypeSpecifics = [];
    },
    RESET_SELECTED_SPECIFICATION_VALUES(state) {
      state.selectedSpecificationValues = {
        fireResistances: [],
        rwAdaptation: {
          range: [0, 0],
          offset: '',
        },
        lnwAdaptation: {
          range: [0, 0],
          offset: '',
        },
        customFilters: [],
      };
    },
    SET_IS_INITIAL_LOADING(state, isInitialLoading) {
      state.isInitialLoading = isInitialLoading;
    },
    SET_ACTIVE_STEP_INDEX(state, activeStepIndex) {
      state.activeStepIndex = activeStepIndex;
    },
    SET_SEARCH_TYPE(state, searchType) {
      state.searchType = searchType;
    },
    SET_DB_VALUES_REGIONS(state, DBRegionValues) {
      state.DBValues.regions = DBRegionValues;
    },
    SET_DB_VALUES_BUILDING_TYPES(state, DBBuildingTypeValues) {
      state.DBValues.buildingTypes = DBBuildingTypeValues;
    },
    SET_DB_VALUES_BUILDING_CLASSES(state, DBBuildingClassValues) {
      state.DBValues.buildingClasses = DBBuildingClassValues;
    },
    SET_DB_VALUES_ASSEMBLY_TYPES(state, DBAssemblyTypeValues) {
      state.DBValues.assemblyTypes = DBAssemblyTypeValues;
    },
    SET_DB_VALUES_ASSEMBLY_TYPE_SPECIFICS(state, DBAssemblyTypeSpecificValues) {
      state.DBValues.assemblyTypeSpecifics = DBAssemblyTypeSpecificValues;
    },
    SET_DB_VALUES_DISABLED_ASSEMBLY_TYPES(state, DBDisabledAssemblyTypes) {
      state.DBValues.disabledAssemblyTypes = DBDisabledAssemblyTypes;
    },
    SET_SELECTED_VALUES(state, selectedValues) {
      state.selectedValues = { ...state.selectedValues, ...selectedValues };
    },
    SET_FILTER_IS_ACTIVE(state, filterIsActive) {
      state.filterIsActive = filterIsActive;
    },
    SET_SELECTED_SPECIFICATION_VALUES(state, selectedSpecificationValues) {
      state.selectedSpecificationValues = selectedSpecificationValues;
    },
    SET_ALL_PRODUCTS_IS_LOADING(state, allProductsIsLoading) {
      state.allProductsIsLoading = allProductsIsLoading;
    },
    SET_ALL_PRODUCTS(state, allProducts) {
      state.allProducts = allProducts;
    },
    SET_FILTERED_PRODUCTS(state, filteredProducts) {
      state.filteredProducts = filteredProducts;
    },
  },
  actions: {
    resetSearchState: ({ commit }) => {
      commit('RESET_SEARCH_STATE');
    },
    resetSelectedSpecificationValues: ({ commit }) => {
      commit('RESET_SELECTED_SPECIFICATION_VALUES');
    },
    updateActiveStepIndex: ({ commit }, activeStepIndex) => {
      commit('SET_ACTIVE_STEP_INDEX', activeStepIndex);
    },
    nextActiveStepIndex: ({ state, commit }) => {
      commit('SET_ACTIVE_STEP_INDEX', state.activeStepIndex + 1);
    },
    updateSearchType: ({ commit }, searchType) => {
      commit('SET_SEARCH_TYPE', searchType);
    },
    updateDBValues: ({ commit }) => {
      commit('SET_IS_INITIAL_LOADING', true);

      const loadBuildingAssemblyTypes = getDBSelectorValues(DBAssemblyTypesRef)
        .then((DBAssemblyTypeValues) => {
          commit('SET_DB_VALUES_ASSEMBLY_TYPES', DBAssemblyTypeValues);
        });
      const loadRegions = getDBSelectorValues(DBRegionsRef)
        .then((DBRegionValues) => {
          commit('SET_DB_VALUES_REGIONS', DBRegionValues);
        });
      const loadBuildingTypes = getDBSelectorValues(DBBuildingTypesRef)
        .then((DBBuildingTypeValues) => {
          commit('SET_DB_VALUES_BUILDING_TYPES', DBBuildingTypeValues);
        });
      const loadBuildingClasses = getDBSelectorValues(DBBuildingClassesRef)
        .then((DBBuildingClassValues) => {
          commit('SET_DB_VALUES_BUILDING_CLASSES', DBBuildingClassValues);
        });

      try {
        return Promise.all([
          loadBuildingAssemblyTypes,
          loadRegions,
          loadBuildingTypes,
          loadBuildingClasses,
        ]).then(() => Promise.resolve());
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      } finally {
        commit('SET_IS_INITIAL_LOADING', false);
      }
    },
    updateAssemblySpecifics: async ({ state, commit }) => {
      try {
        const DBAssemblySpecificsRef = DBFiltersRef
          .doc(state.selectedValues.region)
          .collection('buildingTypes')
          .doc(state.selectedValues.buildingType)
          .collection(state.selectedValues.assemblyType);

        return getDBSelectorValues(DBAssemblySpecificsRef)
          .then((DBAssemblyTypeSpecificValues) => {
            commit('SET_DB_VALUES_ASSEMBLY_TYPE_SPECIFICS', DBAssemblyTypeSpecificValues);
            return Promise.resolve(DBAssemblyTypeSpecificValues);
          });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateDisabledAssemblyTypes: ({ state, commit }) => {
      try {
        DBFiltersRef
          .doc(state.selectedValues.region)
          .collection('buildingTypes')
          .doc(state.selectedValues.buildingType)
          .get()
          .then((buildingTypeDoc) => {
            commit('SET_DB_VALUES_DISABLED_ASSEMBLY_TYPES', buildingTypeDoc.data().disabledAssemblyTypesByBuildingClasses);
          });
      } catch (error) {
        console.error(error);
      }
    },
    updateSelectedValues: ({ state, commit }, selectedValues) => {
      commit('SET_SELECTED_VALUES', selectedValues);
      if (!state.filterIsActive) commit('SET_FILTER_IS_ACTIVE', true);
    },
    updateSelectedSpecificationValues: async ({ commit }, selectedSpecificationValues) => {
      commit('SET_SELECTED_SPECIFICATION_VALUES', selectedSpecificationValues);
      return Promise.resolve();
    },
    updateAllProducts: async ({ state, commit }) => {
      commit('SET_ALL_PRODUCTS_IS_LOADING', true);

      try {
        const productsByAssemblyType = [];

        const querySnapshot = await FBFirestoreRef
          .collection('products')
          .doc('types')
          .collection(state.selectedValues.assemblyType)
          .where('status', '==', 1)
          .limit(firstLoadProductLimit)
          .get();

        querySnapshot.docs.forEach((productDocument) => {
          productsByAssemblyType.push({
            id: productDocument.id,
            data: productDocument.data(),
          });
        });

        if (querySnapshot.docs.length < firstLoadProductLimit) {
          commit('SET_ALL_PRODUCTS', productsByAssemblyType);
          commit('SET_ALL_PRODUCTS_IS_LOADING', false);
          return Promise.resolve(productsByAssemblyType.length);
        }

        const lastVisibleDocument = querySnapshot.docs[querySnapshot.docs.length - 1];

        return FBFirestoreRef
          .collection('products')
          .doc('types')
          .collection(state.selectedValues.assemblyType)
          .where('status', '==', 1)
          .startAfter(lastVisibleDocument)
          .get()
          .then((secondQuerySnapshot) => {
            secondQuerySnapshot.docs.forEach((product) => {
              productsByAssemblyType.push({
                id: product.id,
                data: product.data(),
              });
            });

            commit('SET_ALL_PRODUCTS', productsByAssemblyType);
            commit('SET_ALL_PRODUCTS_IS_LOADING', false);

            return Promise.resolve(productsByAssemblyType.length);
          });
      } catch (error) {
        console.error(error);
        commit('SET_ALL_PRODUCTS_IS_LOADING', false);
        return Promise.reject(error);
      }
    },
    applySearchFilters: ({ state, commit }) => {
      const filteredProducts = [];

      for (let i = 0; i < state.allProducts.length; i += 1) {
        const productData = state.allProducts[i].data;

        // Checking Rw offset
        const rwMin = state.selectedSpecificationValues.rwAdaptation.range[0];
        const rwMax = state.selectedSpecificationValues.rwAdaptation.range[1];
        let rwValueOffset = 0;
        if (state.selectedSpecificationValues.rwAdaptation.offset !== '') {
          rwValueOffset = productData[state.selectedSpecificationValues.rwAdaptation.offset];
        }
        if (productData.rw + rwValueOffset < rwMin
          || productData.rw + rwValueOffset > rwMax) continue;

        // Checking Lnw offset
        const lnwMin = state.selectedSpecificationValues.lnwAdaptation.range[0];
        const lnwMax = state.selectedSpecificationValues.lnwAdaptation.range[1];
        let lnwValueOffset = 0;
        if (state.selectedSpecificationValues.lnwAdaptation.offset !== '') {
          lnwValueOffset = productData[state.selectedSpecificationValues.lnwAdaptation.offset];
        }
        if (productData.lnw + lnwValueOffset < lnwMin
          || productData.lnw + lnwValueOffset > lnwMax) continue;

        if (state.selectedSpecificationValues.fireResistances.length) {
          // If the selected FR values are not all available in the product data, filter it out
          if (!arrayContainsArray(productData.fireResistanceFilters,
            state.selectedSpecificationValues.fireResistances)) continue;

          // Checking the customFilters
          if (!state.selectedSpecificationValues.customFilters
            .every(filterValue => arrayContainsArray(productData[filterValue],
              state.selectedSpecificationValues.fireResistances))) continue;
        }

        filteredProducts.push(state.allProducts[i]);
      }

      commit('SET_FILTERED_PRODUCTS', filteredProducts);
    },
  },
};
