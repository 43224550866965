<template>
  <div>
    <div class="flex flex-row justify-between items-center h-74
            text-pure-white bg-faded-red-two cursor-pointer"
         @click.stop.prevent="onSelectorPress"
    >
      <p class="text-lg leading-19 uppercase px-24 truncate">
        <span v-if="$i18n.locale !== 'fr'" >{{ $t('home.predefined_search.region_selector_placeholder_text')}}</span>
        <span v-if="$i18n.locale === 'fr'" >France</span>
      </p>
      <IconArrow
          :size="30"
          :stroke-width="2"
          direction="down"
          class="mr-34"
      />
    </div>
    <Transition name="zoom-in-bottom">
      <div
          v-show="regionSelectorIsVisible"
          class="selector-items absolute h-408 overflow-y-auto scrolling-touch px-40 bg-pure-white"
      >
        <div
            v-for="(regionOption, index) in regionOptions"
            :key="`region-option-${index}`"
            class="relative text-style-7 py-10 cursor-pointer"
            :class="{
                'pt-23': index === 0,
                'pb-23': index === regionOptions.length - 1,
            }"
            @click="onItemPress(regionOption)"
        >
          <p class="hover:text-faded-red">
            {{ regionOption.label }}
          </p>
        </div>
      </div>
    </Transition>
  </div>
</template>


<script>
import IconArrow from '@/components/icon/IconArrow';
import IconCheckbox from '@/components/icon/IconCheckbox';
import { mapState, mapActions } from 'vuex';
import { sortBy } from 'lodash';
import { addClass, removeClass } from '@/utils/dom';


export default {
  components: {
    IconArrow,
    IconCheckbox,
  },
  data: () => ({
    regionSelectorIsVisible: false,
    regionSelectorVisibleClassname: 'region-selector--is-visible',
  }),
  created() {
    document.addEventListener('click', this.onDocumentPress);
    // document.addEventListener('touchstart', this.onDocumentPress);
  },
  computed: {
    ...mapState('assemblySearch', {
      DBValues: 'DBValues',
    }),
    regionOptions() {
      return this.formatDBValues(this.DBValues.regions);
    },
  },
  methods: {
    ...mapActions('assemblySearch', {
      updateSelectedValues: 'updateSelectedValues',
    }),
    onDocumentPress() {
      // Closing sub nav when clicking outside of it
      if (!document.body.classList.contains(this.regionSelectorVisibleClassname)
        || !this.regionSelectorIsVisible) return;

      this.regionSelectorIsVisible = false;
      removeClass(document.body, this.regionSelectorVisibleClassname);
    },
    onSelectorPress() {
      this.regionSelectorIsVisible = true;
      addClass(document.body, this.regionSelectorVisibleClassname);
    },
    onItemPress(option) {
      removeClass(document.body, this.regionSelectorVisibleClassname);
      this.updateSelectedValues({
        region: option.value,
      }).then(() => {
        this.$router.push({ name: 'AssemblySearchView', params: { searchType: 'preset' } });
      });
    },
    formatDBValues(DBValues) {
      if(this.$i18n.locale === 'fr') return [];
      return sortBy(DBValues, [o => o.data.order]).map(option => ({
        value: option.value,
        label: option.data[this.$i18n.locale].name,
      }));
    },
  },
  destroyed() {
    document.removeEventListener('click', this.onDocumentPress);
    // document.removeEventListener('touchstart', this.onDocumentPress);
  },
};
</script>


<style lang="scss" scoped>
$selector-spacing: 32px;

.selector-items {
  left: $selector-spacing;
  right: $selector-spacing;
  bottom: $selector-spacing;

  .icon {
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
